<template>
    <div v-if="device">
        <div class="container repair-container">

            <div class="row">
                <div class="col-md-12">
                    <label class="mt-2">Confirm parts used and post-repair description</label>
                </div>
            </div>

            <div class="row mb-4">
                <div class="col-md-12">
                    <div class="card card-body pb-4">
                        <empty-state
                            align="left"
                            faIcon="microchip"
                            inline
                            small
                            title="No parts were used"
                            v-if="deviceParts.length === 0 && devicePartsFaulty.length === 0">
                        </empty-state>
                        <dl v-if="deviceParts.length > 0">
                            <dt>Parts used</dt>
                            <dd v-for="part in deviceParts" :key="part.id">
                                {{ part.stock_item_title }}
                            </dd>
                        </dl>
                        <dl v-if="devicePartsFaulty.length > 0">
                            <dt>Faulty parts</dt>
                            <dd v-for="part in devicePartsFaulty" :key="part.id">
                                {{ part.stock_item_title }}
                            </dd>
                        </dl>
                        <dl v-if="deviceParts.length === 0">
                            <dt>Reason for no parts used</dt>
                            <select v-model="noPartsUsedReason" class="custom-select" @change="updateNoPartsUsedReason()">
                                <option :value="null">Select reason</option>
                                <option value="No fault found">No fault found</option>
                                <option value="Customer education">Customer education</option>
                                <option value="Blacklisted">Blacklisted</option>
                                <option value="Unauthorised repair">Unauthorised repair</option>
                                <option value="Water damaged">Water damaged</option>
                                <option value="Impact damaged">Impact damaged</option>
                            </select>
                        </dl>

                        <button @click="editPartsUsed" class="btn btn-info btn-sm mb-4 align-self-start">Edit parts</button>

                        <template v-if="!device.parts_confirmed">
                            <empty-state
                                v-if="deviceSyncStatus && (deviceSyncStatus.code === 'checking' || deviceSyncStatus.code === 'updating')"
                                class="bg-transparent pb-0 pt-3"
                                faIcon="cogs"
                                small
                                :title="deviceSyncStatus.value"
                            ></empty-state>
                            <empty-state
                                v-if="deviceSyncStatus && deviceSyncStatus.code === 'error'"
                                class="bg-transparent pb-0 pt-3"
                                faIcon="battery-bolt"
                                small
                                :title="'Error: ' + deviceSyncStatus.value"
                            ></empty-state>
                            <p v-if="deviceSyncStatus && deviceSyncStatus.code === 'error'" class="mb-0 mt-3 text-center">
                                There has been an error while checking parts linked with GSPN, please check the parts list, or call tech support on {{ techSupportNumber }} to bypass these checks.
                                <button @click="editPartsUsed" class="btn btn-md btn-gradient-info ml-0">Edit parts</button>
                                <button v-if="isSuperAdmin" class="btn btn-md btn-gradient-warning ml-0" @click="resolveGspnLater()">Resolve Later</button>
                            </p>
                            <button :disabled="deviceSyncStatus
                                    && (deviceSyncStatus.code === 'checking'
                                    || deviceSyncStatus.code === 'updating')
                                    || deviceParts.length === 0 && !noPartsUsedReason"
                                    class="btn btn-lg btn-success ml-0" @click="confirmPartsUsed()">
                                I confirm all parts used are listed above
                            </button>
                            <p class="mb-0 mt-3 text-center text-sm"><i class="fal fa-exclamation-circle"></i> Check all the parts used are listed above. <strong>Any inaccuracies can affect your KPIs</strong></p>
                        </template>
                        <div class="alert alert-success alert-centered mb-0" v-if="device.parts_confirmed && !device.review_complete"><animated-info></animated-info> Parts used confirmed</div>
                    </div>

                    <div class="card card-body pb-0">
                        <div class="form-group">
                            <h5>Enter a brief description of work done <span class="float-right text-sm text-muted">{{ descriptionCharactersRemaining }}</span></h5>
                            <textarea v-model="completeDescription" class="form-control" rows="3" placeholder="Enter a description"></textarea>
                            <p class="text-left text-muted text-xs pl-1 mb-0">Enter a minimum of 20 characters</p>
                        </div>
                        <button v-if="!savedDescription" :disabled="savingDescription || !completeDescription || completeDescription.length < 20" class="btn btn-lg btn-success mb-3" @click="saveDescription()">
                            Save Repair Details
                        </button>
                        <div v-if="savedDescription" class="alert alert-success alert-centered mb-0"><animated-info></animated-info> Repair Description Saved</div>
                        <p v-if="savedDescription" @click="savedDescription = false" class="text-sm text-right mb-3 cursor-pointer text-underline">Edit</p>
                    </div>
                </div>
            </div>

            <!-- Footer -->
            <fixed-footer
                :class="{'active': footerActive}"
                :nextLabel="this.nextRepairStep($route.name).title"
                :nextRoute="this.nextRepairStep($route.name).routeName"
                :prevRoute="this.prevRepairStep($route.name).routeName">
            </fixed-footer>

        </div>
    </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';

export default {
    props:["jobId", "deviceId"],

    data() {
        return {
            noPartsUsedReason: null,
            completeDescription: '',
            savingDescription: false,
            savedDescription: false
        }
    },

    mounted() {
        this.loadDeviceForJob({
            jobId: this.jobId,
            deviceId: this.deviceId,
        }).then(() => {
            this.setCurrentStep({
                jobId: this.jobId,
                deviceId: this.deviceId,
                currentStep: this.$route.name,
            });
            this.$nextTick(()=> {
                this.$set(this.$data, 'completeDescription', this.device.complete_description);
                this.$set(this.$data, 'noPartsUsedReason', this.device.no_parts_used_reason);
                if (this.device.complete_description.length > 0) {
                    this.savedDescription = true;
                }
            });
        });
    },

    computed: {
        ...mapGetters([
            "device",
            "devicePartsFaulty",
            "deviceParts",
            "deviceSyncStatus",
            "nextRepairStep",
            "prevRepairStep",
            "techSupportNumber",
            "isSuperAdmin"
        ]),

        descriptionCharactersRemaining() {
            if (!this.completeDescription) return 40;
            let remaining = (40 - this.completeDescription.length);
            if (remaining < 0) {
                return 0;
            }
            return remaining;
        },

        /** Check if footer should be active */
        footerActive() {
            if (
                !this.savedDescription || !this.device.parts_confirmed ||
                (this.deviceSyncStatus && this.deviceSyncStatus.code !== 'complete' && this.deviceSyncStatus.code !== 'bypassed')
            ) {
                return false;
            }

            return true;
        }
    },

    methods: {
        ...mapActions([
            "loadDeviceForJob",
            "setCurrentStep",
            "setNoPartsRequired",
            "clearPartsUsedConfirmed",
            "loadDeviceSyncStatus",
            "setPartsUsedConfirmed",
            "syncServiceOrderWithGspn",
            "bypassGspnSyncErrors",
            "storeCompleteDescription"
        ]),

        updateNoPartsUsedReason() {
            this.setNoPartsRequired({
                jobId: this.jobId,
                deviceId: this.deviceId,
                noPartsUsedReason: this.noPartsUsedReason
            });
        },

        editPartsUsed() {
            this.clearPartsUsedConfirmed({
                jobId:this.jobId,
                deviceId:this.deviceId
            }).then(() => {
                this.$router.push({name: 'repair.addParts'});
            });
        },

        confirmPartsUsed() {
            this.loadDeviceSyncStatus({
                jobId: this.jobId,
                deviceId: this.device.id
            }).then(() => {
                this.syncServiceOrderWithGspn({deviceId: this.deviceId}).then(() => {
                    this.setPartsUsedConfirmed({
                        jobId: this.jobId,
                        deviceId: this.deviceId
                    });
                }).catch(error => {
                    this.errors.push(error);
                });
            });
        },

        resolveGspnLater() {
            this.bypassGspnSyncErrors({deviceId: this.device.id});
        },

        saveDescription() {
            this.savingDescription = true;
            this.storeCompleteDescription({
                deviceId: this.deviceId,
                completeDescription: this.completeDescription
            }).then(() => {
                this.savingDescription = false;
                this.savedDescription = true;
            })
        }
    }
}
</script>

<style scoped>

</style>